/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

.App {
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.sectionContainer {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}
/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}
nav.navbar a.navbar-brand { 
    width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
    display: inline-block;
    margin-left: 14px;
}
.social-icon a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}



/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 200px;
  overflow: hidden;
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  animation: updown 3s linear infinite;
  filter: none !important; 
  background-color: transparent !important; 
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid white;
}

/* Home Page Cards Section */
@media screen and (max-width: 768px) {
  .homePageCardsContainer {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .cards {
    padding: 50px 0; 
  }
}

.project h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
  color: #fff;
}

.project p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  text-align: center;
  margin: 14px auto 30px auto;
  width: 56%;
}

.proj-imgbx {
  position: relative;
  overflow: hidden;
  border-radius: 30px;
  margin-bottom: 24px;
  transition: 0.4s ease-in-out;
  min-width: 400px; 
  margin: 50px;
}

.proj-imgbx img {
  width: 100%;
  height: auto;
  transition: transform 0.4s ease-in-out;
}

.proj-imgbx:hover::before {
  opacity: 0.85;
  height: 100%;
}

.proj-txtx {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.5s ease-in-out, top 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}

.proj-imgbx:hover .proj-txtx {
  opacity: 1;
}

.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 0.8px;
}

.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  color: #fff;
  letter-spacing: 0.8px;
}

.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  position: absolute;
  width: 100%;
  height: 0;
  transition: height 0.4s ease-in-out;
}

@keyframes slowBounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.continuousBounce {
  animation: slowBounce 2.5s infinite;
}

/* About Me Section */
.aboutMeContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  text-align: center;
  background-color: white;
  width: 100%;
  min-height: 100vh; 
  padding-top: 2rem;
}

.aboutMeContent {
  display: flex;
  flex-direction: row; 
  justify-content: center;
  align-items: flex-start; 
  gap: 4rem; 
  width: 90%; 
  max-width: 1200px; 
  margin: 0 auto; 
  height: auto;
  margin-top: 0; 
}

.aboutMeImage {
  flex: 1; 
  width: 90%; 
  justify-content: center;
  align-items: center;
}

.aboutMeImage img {
  display: block; 
  width: 90%;
  height: auto;
  margin: 0; 
}

.text {
  flex: 1;  
  width: 50%;
  padding: 0.05px;
  height: auto;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; 
  align-items: flex-start; 
}

.text h1 {
  color: black;
  font-size: 3.5em;
  font-weight: bold;
  margin: 0;
  padding-top: 0;
  padding-left: 5px;
  text-align: center;
}

.text p {
  width: 100%; 
  text-align: left; 
  margin: 0.5rem; 
  font-size: 1em; 
  color: #333; 
  line-height: 1.6; 
}

.text p span {
  font-size: 1.5em; 
}

@media screen and (max-width: 768px) {
  .aboutMeContent {
    flex-direction: column;
    align-items: center; 
    width: 100%; 
  }

  .aboutMeImage, .text {
    width: 100%; 
    margin: 0 auto; 
    flex: none; 
  }

  .text {
    padding: 0; 
    align-items: center; 
  }

  .text h1 {
    font-size: 2em; 
    margin-top: 0;
    text-align: center; 
  }

  .text p, .text p span {
    text-align: center; 
  }
}

.publications {
  text-align: left; 
  padding-left: 7px; 
  margin-top: 4px; 
}

.publications ul {
  list-style-type: disc; 
  margin: 0; 
  padding: 0; 
}

.publications li {
  margin-bottom: 10px; 
}

.publications a {
  color: grey; 
  text-decoration: none; 
}

.publications a:hover {
  text-decoration: underline; 
}

/* Work Experience Page */
.workExpPage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 4rem 0;
  background-color: white;
  width: 100%;
  min-height: 100vh; 
}

.workCard {
  border-color: #007bff; 
  border-width: 1px;
  border-style: solid;
  border: 1px solid grey; 
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
  background-color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  height: 100%; 
}

.imageContainer {
  display: flex;
  justify-content: center; 
  align-items: center; 
  height: 120px; 
  width: 100%; 
  margin-top: 20px; 
  overflow: hidden; 
  background-color: white;
}

.workCardImg {
  max-height: 100%; 
  max-width: 100%; 
  object-fit: scale-down; 
  margin: 0 auto; 
  background-color: white; 
}

.workCard .card-title {
  color: #333; 
  font-size: 1.25rem;
  font-weight: bold; 
  margin-top: 0.5rem; 
  margin-bottom: 0.5rem;
  font-weight: normal;
}

.workCard .card-subtitle {
  font-weight: bold;
  color: black;
}

.workCard .card-text small {
  display: block; 
  color: #666; 
  margin-bottom: 0.5rem; 
  display: block;
  text-align: center; 
  margin-bottom: 1rem; 
}

.workCard .card-text {
  font-size: 0.9rem; 
  color: #444; 
  line-height: 1.4; 
  margin-bottom: 1rem; 
  text-align: left; 
  margin-bottom: 1rem; 
}

.workCard .techStack {
  font-size: 0.85rem; 
  font-weight: bold; 
  color: #333; 
  margin-bottom: 1rem; 
  font-weight: bold; 
  margin-bottom: 1.5rem; 
  text-align: center;
}

.workCard .card-body {
  flex-grow: 1; 
}

.workExpHeader {
  color: black; 
  text-align: center; 
  margin-bottom: 1rem; 
  font-size: 2rem; 
}

.resumeLink {
  color: grey; 
  text-align: center; 
  margin-bottom: 6rem; 
  display: block; 
  font-size: 1.25rem; 
}

.workExpHeader h2 {
  font-size: 4.5rem;
}

.workCard .skillsText {
  font-size: 15px;
  text-align: center;
  font-weight: bold;
  color: grey;
}

.resumeLink .resumeButton {
  display: inline-block;
  padding: 10px 20px; 
  border:  1px solid grey; 
  background-color: transparent; 
  text-align: center;
  text-decoration: none;
  color: black; 
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s; 
  border-radius: 15px; 
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
}

.resumeLink .resumeButton:hover {
  background-color: black; 
  color: white; 
}

/* Projects Page */
.viewRepoButton {
  display: inline-block;
  padding: 10px 20px;
  border: 2px solid #4b4b4b; 
  background-color: transparent;
  text-align: center;
  text-decoration: none;
  color: #4b4b4b; 
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 20px; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); 
  margin: auto;
  align-self: center;
}

.viewRepoButton:hover {
  background-color: #4b4b4b; 
  color: white;
}

.viewGithubButton {
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid grey; 
  background-color: transparent;
  text-align: center;
  text-decoration: none;
  color: #333; 
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 15px; 
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
  margin-bottom: 5rem;
  font-size: 20px;
}

.viewGithubButton:hover {
  background-color: #333; 
  color: white;
}

.projectCard {
  border: 1px solid grey;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  background-color: white;
  margin: 1rem;
  transition: transform 0.3s ease;
}

.projectCardImg {
  max-height: 120px;
  object-fit: contain;
  padding: 1rem;
}

.projectCard .card-title {
  font-weight: bold;
  font-size: 1.25rem;
  margin: 0.5rem 0;
}

.projectCard .card-text {
  font-size: 0.9rem;
  color: #444;
  line-height: 1.4;
  margin-bottom: 1rem;
}

.projectCard .techStack {
  font-size: 0.85rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1.5rem;
}

.card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-15px);
  box-shadow: 6px 20px 20px rgba(0, 0, 0, 0.5);
}

.projectCard:hover {
  transform: translateY(-15px);
  box-shadow: 6px 20px 20px rgba(0, 0, 0, 0.5);
}

.backToTopButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #000; 
  color: #fff;
  border: none;
  border-radius: 15px; 
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  font-size: 24px; 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}

.backToTopButton:hover {
  background-color: #333; 
}

@media (max-width: 991px) {
  nav.navbar .navbar-brand {
    margin-right: auto; 
  }

  nav.navbar .navbar-toggler {
    margin-left: auto; 
  }

  nav.navbar .navbar-nav .nav-link {
    padding: 0 1rem; 
  }

  .social-icon,
  .navbar-text {
    display: none;
  }
  
  nav.navbar .navbar-collapse {
    text-align: right; 
  }
}

@media (max-width: 768px) {
  .sectionContainer {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .aboutMeContainer,
  .workExpPage,
  .projectContainer { 
    padding: 20px; 
    height: auto; 
  }

  .aboutMeContent,
  .workContent,
  .projectContent { 
    flex-direction: column; 
    align-items: center; 
  }

  .aboutMeImage img,
  .workImage img,
  .projectImage img { 
    width: 100%; 
    height: auto; 
  }

  .text,
  .workText,
  .projectText { 
    width: 100%; 
    text-align: center; 
    padding: 0 15px; 
  }
}
